<template>
  <div>
    <el-form :model="search">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="请输入卡号">
            <el-input v-model="search.card_number" placeholder="请输入卡号" />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="请输入产品名称">
              <el-input v-model="search.proname" placeholder="请输入产品名称" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请选择绑定状态">
            <el-select placeholder="请选择绑定状态" v-model="search.is_use">
              <el-option label="未绑定" :value="0" />
              <el-option label="已绑定" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请选择服务状态">
            <el-select placeholder="请选择服务状态" v-model="search.status">
              <el-option label="待使用" :value="0" />
              <el-option label="服务中" :value="1" />
              <el-option label="已完成" :value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <el-button type="primary" @click="onSearchBtn" :icon="Search"
              >搜索</el-button
            >
             <el-button
                      type="primary"
                      icon="CirclePlus"
                      @click="addCardShow = true"
                    >
                      添加
                    </el-button>
            <el-button type="primary" @click="templateDownload">
              模板下载
            </el-button>
            <el-button type="primary" @click="excelImport">导入</el-button>
            <el-button
              :loading="loading"
              type="success"
              @click="onPayTheFeesexport"
            >
              导出
            </el-button>
          </el-form-item>
        </el-col>
      <!--  <el-col :span="1">
          <el-button
            :loading="loading"
            type="success"
            @click="onCustomerexport"
          >
            导出
          </el-button>
        </el-col> -->
      </el-row>
    </el-form>

    <!-- 表格 -->

    <el-table
      :data="listData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="id" label="序号" width="100px" />
      <el-table-column prop="card_number" label="卡号" width="200px" />
      <el-table-column prop="proname" label="产品名称" width="200px" />
      <el-table-column prop="card_times" label="总发货次数" width="100px" />
      <el-table-column prop="expiration_date" label="到期时间" width="150px" />
      <el-table-column label="服务状态" width="100px">
        <template v-slot="{ row }">
          {{ row.status==0 ? '待使用' : row.status==1 ?'服务中' :'已完成'}}
        </template>
      </el-table-column>
      <el-table-column label="绑定状态" width="100px">
        <template v-slot="{ row }">
          {{ row.is_use ? '已绑定' : '未绑定' }}
        </template>
      </el-table-column>
      <el-table-column prop="use_time" label="绑定时间" width="180px" />
      <el-table-column prop="nickname" label="绑定用户昵称" width="150px" />
      <el-table-column prop="wxuser_openid" label="绑定用户openid" width="220px" />
      <el-table-column label="操作" width="150px" >
        <template #default="{ row }">
          <el-button
            size="small"
            type="success"
            @click="router.push(`/card/detail/${row.id}`)"
          >
            详情</el-button
          >
           <el-button type="primary" size="small" @click="onEditNameBtn(row)"
                      >修改</el-button
                    >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

     <!-- 添加海鲜卡 -->
        <addCard
          v-model="addCardShow"
          :editorial="editorial"
          :onListData="onListData"
        >
        </addCard>
        <editname
              v-model="editproname"
              :cardinfo="cardinfo"
              :onListData="onListData"
        ></editname>
  </div>
</template>

<script setup>
// articleListAPI
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Search } from '@element-plus/icons-vue'
import { cardListAPI, reportRecordAPI } from '@/api/card'
import addCard from './compont/addCard.vue'
import editname from './compont/editCard.vue'
const editproname = ref(false)

/**
 *  表单 S
 */
// 表单数据源
const search = ref({
  class_id: '',
  title: '',
  page: 1,
  limit: 10,
  total: 0
})

// 搜索
const onSearchBtn = () => {
  onListData()
}

/**
 *  表单 E
 */

/*
 *  表格： S
 */

// 列表
const listData = ref([])
const onListData = async () => {
  const { data, total } = await cardListAPI(search.value)
  search.value.total = total
  listData.value = data
}
onListData()

// 模板下载
const templateDownload = () => {
  window.location.href =
    'https://hxdh.yslts.com/excel/海鲜卡导入模板.xlsx'
}

// Excel 导入
const router = useRouter()
const excelImport = () => {
  router.push('/card/import')
}

// 修改姓名
const cardinfo = ref({})
const onEditNameBtn = (row) => {
  cardinfo.value = row
  console.log(cardinfo.value)
  editproname.value = true
}

/** 导出 E */
const loading = ref(false)
const onPayTheFeesexport = async () => {
  loading.value = true
  const data = await reportRecordAPI(search.value)
  window.location.href = `https://hxdh.yslts.com${data}`
  loading.value = false
}

/**
 *  分页 S
 */

// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onListData()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onListData()
}

/**
 *  分页 E
 */

/**
 *  弹窗组件 S
 */
const addCardShow = ref(false)

</script>

<style lang="scss" scoped></style>
